import React, { useState, useEffect } from "react";
import {
  Grid,
  Stack,
  Card,
  CardContent,
  Box,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  FormControlLabel,
  Typography,
  Checkbox,
  Alert,
  Snackbar,
  Switch,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import "./styles.css";
import VerifiedIcon from "@mui/icons-material/Verified";
import axios from "axios";
import DOMPurify from "dompurify";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useHistory, useLocation } from "react-router-dom";
import Header from "../header/header";
import { countries } from "../utility/country";
import { languageMap } from "../utility/languageMap";
import jwt_decode from "jwt-decode";
import {
  getHeaders,
  validatePhoneNumber,
  validateEmail,
} from "../utility/utility";
import SuccessMessage from "../success/success";
import OtpValidator from "../otp-validator/otpValidator";
import moment from "moment";
import { Country, State, City }  from 'country-state-city';

import { createTheme, ThemeProvider } from "@mui/material/styles";
import OCRModal from "./OCRModal";

const theme = createTheme();
theme.typography.h3 = {
  fontSize: "1.2rem",
};

theme.typography.h5 = {
  fontSize: "1.5rem",
};

function generatePayloadObj(registerObj) {
  const payload = {
    customer: {
      name: {
        firstName: registerObj?.firstName || "",
        middleName: registerObj?.middleName || null,
        lastName: registerObj?.lastName || null,
        lastNameMaternal: registerObj?.lastNameMaternal || null,
        lastNamePaternal: registerObj?.lastNamePaternal || null,
        nameTypeId: registerObj?.nameTypeId || "D",
      },
      customerUmn: registerObj?.customerUmn || null,
      dob: registerObj.dob
        ? moment(registerObj.dob).format("YYYY-MM-DD")
        : null,
      genderType: registerObj?.gender || null,
    },
    address: [
      {
        addressType: "1",
        streetAddress1: registerObj?.streetAddress1 || registerObj?.city,
        streetAddress2: registerObj?.streetAddress2 || null,
        city: registerObj?.city || "",
        countryCodeIso2: registerObj?.country || "",
        stateProvince: registerObj?.state || "",
        zipPostalCode: registerObj?.zipCode || "",
        addressFormat: "D",
      },
    ],
    phone: [
      {
        phoneType: "1",
        phoneSeq: "1",
        phoneNumber: registerObj?.phoneNumber || "",
        isdCode: getCountryIsdCodeByCode(
          registerObj?.countryCode
        )?.isdCode.replace("+", ""),
        countryIsdCode: registerObj?.countryCode || "",
      },
    ],
    email:
      null === registerObj?.email || "error" === registerObj?.email
        ? null
        : [
            {
              emailType: "1",
              emailValue: registerObj?.email || "",
            },
          ],
    preferences: {
      optInForEmail: registerObj?.emailPreference === true ? "Y" : "N" || "Y",
      optInForSms: registerObj?.smsPreference === true ? "Y" : "N" || "N",
      prefLanguageCode: registerObj?.languagePreference || "EN",
    },
    customerUpdateType: registerObj?.customerUpdateType,
    requestInitiatedBy: "CUSOBD",
  };
  return payload;
}

function getCountryCodeByIsdCode(isdCode) {
  return countries.filter(
    (country) =>
      country.isdCode === isdCode || country.isdCode === "+" + isdCode
  )?.[0].code;
}

function setCountryIsdCode(isdCode, countryCodeIso2) {
  if((isdCode=="1" || isdCode=="+1")){
    return countryCodeIso2;
  }
  return getCountryCodeByIsdCode(isdCode);
}

function getCountryIsdCodeByCode(code) {
  return countries.filter((country) => country.code === code)?.[0];
}

function getCustUpdateType(auditInfo) {
  return auditInfo?.createdBy === "CUSOBD" || auditInfo?.updatedBy === "CUSOBD"
    ? "UPDATE_PROFILE_ONLY"
    : "UPDATE_PROFILE_AND_PROMO";
}

function getFramedObj(customerDetails, username) {
  return {
    firstName: customerDetails.customerPersonalInfo.customer.name.firstName,
    middleName: customerDetails.customerPersonalInfo.customer.name.middleName,
    lastName: customerDetails.customerPersonalInfo.customer.name.lastName,
    lastNamePaternal:
      customerDetails.customerPersonalInfo.customer.name.lastNamePaternal,
    lastNameMaternal:
      customerDetails.customerPersonalInfo.customer.name.lastNameMaternal,
    nameTypeId: customerDetails.customerPersonalInfo.customer.name.nameTypeId,
    customerUmn: customerDetails.customerPersonalInfo.customer.customerUmn,
    gender: customerDetails.customerPersonalInfo.customer?.genderType,
    dob: customerDetails.customerPersonalInfo.customer.dob,
    email: customerDetails?.customerEmail?.[0]?.email?.emailValue || null,
    countryCode: setCountryIsdCode(
      customerDetails.customerPhone[0].phone.isdCode, customerDetails.customerAddress[0].address.countryCodeIso2),
    phoneNumber: customerDetails.customerPhone[0].phone.phoneNumber,
    streetAddress1: customerDetails.customerAddress[0].address.streetAddress1,
    streetAddress2: customerDetails.customerAddress[0].address.streetAddress2,
    city: customerDetails.customerAddress[0].address.city,
    state: customerDetails.customerAddress[0].address.stateProvince,
    country: customerDetails.customerAddress[0].address.countryCodeIso2,
    zipCode: customerDetails.customerAddress[0].address.zipPostalCode,
    smsPreference:
      customerDetails?.customerPreferences?.preferences?.optInForSms === "Y"
        ? true
        : false,
    emailPreference:
      customerDetails?.customerPreferences?.preferences?.optInForEmail === "Y"
        ? true
        : false,
    languagePreference:
      customerDetails?.customerPreferences?.preferences?.prefLanguageCode ||
      "EN",
    editRequest: true,
    customerUpdateType: getCustUpdateType(
      customerDetails?.customerPersonalInfo?.auditInfo
    ),
    userName: username,
  };
}

function getFramedObjForNewCustomer(countryCode, phoneNumber, dob) {
  return {
    dob: dob,
    countryCode: countryCode,
    phoneNumber: phoneNumber,
  };
}

const initialState = {
  formIndex: 0,
  firstName: "",
  lastName: "",
  middleName: "",
  lastNameMaternal: "",
  lastNamePaternal: "",
  nameTypeId: "D",
  customerUmn: "",
  gender: "",
  dob: null,
  email: null,
  countryCode: sessionStorage.getItem("countryCode"),
  phoneNumber: "",
  streetAddress1: "",
  streetAddress2: "",
  city: "",
  state: "",
  country: sessionStorage.getItem("countryCode"),
  zipCode: "",
  smsPreference: true,
  emailPreference: true,
  languagePreference: "EN",
  mandatoryMessage: "",
  registeredSuccessful: false,
  statusMessage: "",
  userName: "",
  editRequest: false,
  issueCountry: "",
  idType: "",
  idSubType: "",
  docSide: "front",
  customerUpdateType: "REGISTRATION",
};

const labels = {
  firstName: "First Name",
  lastName: "Last Name",
  lastNamePaternal: "Paternal Name",
  lastNameMaternal: "Maternal Name",
  gender: "Gender",
  dob: "Date Of Birth",
  email: "Email",
  countryCode: "Country Code",
  phoneNumber: "Mobile Number",
  streetAddress1: "Street Address",
  streetAddress2: "Street Address 2",
  city: "City",
  state: "State",
  country: "Country",
  zipCode: "Postal Code",
  languagePreference: "Language Preference",
};

const requiredFields = {
  firstName: "",
  lastName: "",
  lastNamePaternal: "",
  lastNameMaternal: "",
  countryCode: "",
  phoneNumber: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  // smsPreference: true,
  // emailPreference: true,
  languagePreference: "",
};

const errorText = "Please fill this field";
const toastMsg = "Some Error Occurred, Please try again";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

function CustomerRegistration(props) {
  const [registerObj, setRegisterObj] = useState({ ...initialState });
  const [backupRegisterObj, setBackupRegisterObj] = useState({
    ...initialState,
  });
  const [helperText, setHelperText] = useState(errorText);
  const [emailErrorText, setEmailErrorText] = useState("");
  const [phoneErrorText, setPhoneErrorText] = useState("");
  const [isRegistrationSuccess, setIsRegistrationSuccess] = useState(false);
  const [errorToastStatus, setErrorToastStatus] = useState(false);
  const [toastMessage, setToastMessage] = useState(toastMsg);
  const [openIDScan, setOpenIDScan] = useState(false);
  const [openOtpValidatorPhone, setOpenOtpValidatorPhone] = useState(false);
  const [phoneOtpVerified, setPhoneOtpVerified] = useState(false);
  const [phoneOtpError, setPhoneOtpError] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [detailsFromPhoto, setDetailsFromPhoto] = useState({});
  const [loading, setLoading] = React.useState(false);
  const [phoneTxnId, setPhoneTxnId] = React.useState(null);
  const [promoterId, setPromoterId] = useState("");
  const [countrySelected, setCountrySelected] = React.useState(registerObj.country);

  const history = useHistory();
  const { state, pathname } = useLocation();

  const getTokenInfo = () => {
    const idToken = sessionStorage.getItem("idToken");
    try {
      const id = jwt_decode(idToken).sub;
      return id;
    } catch (error) {}
  };

  useEffect(() => {
    const idToken = sessionStorage.getItem("idToken");
    try {
      const id = jwt_decode(idToken).sub;
      setPromoterId(id);
      setCountry();
    } catch (error) {}
  }, []);

  useEffect(() => {
    if (!isRegistrationSuccess) {
      
      setRegisterObj({ ...initialState });
      setCountry();
    }
    if (isRegistrationSuccess) {
      setPhoneOtpVerified(false);
    }

    function setCountry() {
      setRegisterObj({
        ...registerObj, ["countryCode"]: sessionStorage.getItem("countryCode"),
        ["country"]: sessionStorage.getItem("countryCode")
      });
    }
  }, [isRegistrationSuccess]);

  useEffect(() => {
    if (!openModal && Object.keys(detailsFromPhoto).length) {
      setCountrySelected(detailsFromPhoto?.country);
      setRegisterObj({ ...initialState, ...detailsFromPhoto });
    }
  }, [openModal, detailsFromPhoto]);

  useEffect(() => {
    if (state?.fromPath === "lookup") {
      setCountrySelected(state.customerLookupResults?.customerAddress[0]?.address?.countryCodeIso2);
      const frameObj = getFramedObj(
        state.customerLookupResults,
        getTokenInfo()
      );
      setRegisterObj({
        ...registerObj,
        ...frameObj,
      }); 

      setBackupRegisterObj({
        ...registerObj,
        ...frameObj,
      });
    }
    getTokenInfo();
  }, [state?.fromPath]);

  useEffect(() => {
    if (state?.fromPath === "lookupWithNewCustomer") {
      const frameObj = getFramedObjForNewCustomer(
        state.countryCode,
        state.phoneNumber,
        state.dob
      );
      setRegisterObj({
        ...registerObj,
        ...frameObj,
      });
    }
    getTokenInfo();
  }, [state?.fromPath]);

  const handleOpenModalForIDScan = () => {
    setOpenIDScan(true);
  };
  const handleCloseModalForIDScan = () => {
    setOpenIDScan(false);
  };

  const handleOpenModalForOtpValidationPhone = () => {
    if (
      validatePhoneNumber(registerObj.countryCode, registerObj.phoneNumber) &&
      registerObj.countryCode
    ) {
      setOpenOtpValidatorPhone(true);
      let submittedObj = {};
      submittedObj.clientKey = "DCCUSOBD";
      submittedObj.firstName = registerObj.firstName;
      submittedObj.phone =
        getCountryIsdCodeByCode(registerObj.countryCode)?.isdCode.substring(1) +
        registerObj.phoneNumber;
      submittedObj.authType = "SMS";
      submittedObj.countryCode = registerObj.countryCode;
      submittedObj.requestInitiatedBy = "CUSOBD";
      axios
        .post(
          `${process.env.REACT_APP_CUS_OBD_BASE_ORDER_SERVICE_URL_FOR_OTP}/v1/pos/otpauth/generate`,
          submittedObj,
          getHeaders(sessionStorage.getItem("accessToken"))
        )
        .then((response) => {
          setPhoneTxnId(response?.data?.authTxnId);
        })
        .catch((error) => {
          console.log("error occurred while generating otp");
        });
    } else {
      setRegisterObj({ ...registerObj, ["phoneNumber"]: "error" });
    }
  };
  const handleCloseModalForOtpValidationPhone = () => {
    setOpenOtpValidatorPhone(false);
  };

  const handleOtpValidationPhoneResendOtp = () => {
    let submittedObj = {};
    submittedObj.clientKey = "DCCUSOBD";
    submittedObj.firstName = registerObj.firstName;
    submittedObj.phone =
      getCountryIsdCodeByCode(registerObj.countryCode)?.isdCode.substring(1) +
      registerObj.phoneNumber;
    submittedObj.authType = "SMS";
    submittedObj.countryCode = registerObj.countryCode;
    submittedObj.requestInitiatedBy = "CUSOBD";

    axios
      .post(
        `${process.env.REACT_APP_CUS_OBD_BASE_ORDER_SERVICE_URL_FOR_OTP}/v1/pos/otpauth/generate`,
        submittedObj,
        getHeaders(sessionStorage.getItem("accessToken"))
      )
      .then((response) => {
        setPhoneTxnId(response?.data?.authTxnId);
      })
      .catch((error) => {
        console.log("error occurred");
      });
  };
  const handleOtpValidationPhoneSubmitOtp = (otp) => {
    let submittedObj = {};
    submittedObj.clientKey = "DCCUSOBD";
    submittedObj.authTxnId = phoneTxnId;
    submittedObj.otpValue = otp;
    submittedObj.requestInitiatedBy = "CUSOBD";

    axios
      .post(
        `${process.env.REACT_APP_CUS_OBD_BASE_ORDER_SERVICE_URL_FOR_OTP}/v1/pos/otpauth/verify`,
        submittedObj,
        getHeaders(sessionStorage.getItem("accessToken"))
      )
      .then((response) => {
        if (response?.data?.status === "SUCCESS") {
          setPhoneOtpVerified(true);
          setOpenOtpValidatorPhone(false);
        } else {
          setPhoneOtpVerified(false);
          setOpenOtpValidatorPhone(true);
          setPhoneOtpError("Please enter valid verification code");
        }
      })
      .catch((error) => {
        console.log("error occurred");
      });
  };

  const onChangeHandler = (e, fieldName) => {
    let value;
    if (fieldName === "dob") {
      value = e !== null ? DOMPurify.sanitize(e.$d) : null;
    } else {
      value = DOMPurify.sanitize(e.target.value);
    }
    if (fieldName === "country") {
      changeSelectOptionHandler(e);
      setRegisterObj({ ...registerObj, [fieldName]: value,["state"]: "" });
    }
    else {
    setRegisterObj({ ...registerObj, [fieldName]: value });
    }
  };

  /** Function that will set different values to state variable
   * based on which dropdown is selected
   */
  const changeSelectOptionHandler = (event) => {
    setCountrySelected(event.target.value);
  };

  const onChangeHandlerChecked = (e, fieldName) => {
    let value = e.target.checked;
    setRegisterObj({ ...registerObj, [fieldName]: value });
  };

  const onBlurHandlerEmail = (e, fieldName) => {
    let value;
    value =
      typeof e.target.value === "string"
        ? DOMPurify.sanitize(e.target.value.trim())
        : DOMPurify.sanitize(e.target.value);
    if (!value) {
      setRegisterObj({ ...registerObj, [fieldName]: null });
    } else {
      const isValid = validateEmail(value);
      if (!isValid) {
        setRegisterObj({ ...registerObj, [fieldName]: "error" });
        setEmailErrorText("Please enter valid email");
      } else {
        setRegisterObj({ ...registerObj, [fieldName]: value });
        setEmailErrorText("");
      }
    }
  };

  const onBlurHandler = (e, fieldName) => {
    let value;
    if (fieldName === "dob") {
      value = DOMPurify.sanitize(e);
    } else {
      value =
        typeof e.target.value === "string"
          ? DOMPurify.sanitize(e.target.value.trim())
          : DOMPurify.sanitize(e.target.value);
    }
    if (!value) {
      setRegisterObj({ ...registerObj, [fieldName]: "error" });
    } else if (fieldName === "phoneNumber") {
      const isValid = validatePhoneNumber(registerObj.countryCode, value);
      if (!isValid) {
        setRegisterObj({ ...registerObj, [fieldName]: "error" });
        setPhoneErrorText("Please enter valid mobile number");
      } else {
        setRegisterObj({ ...registerObj, [fieldName]: value });
        setPhoneErrorText("");
      }
    } else setRegisterObj({ ...registerObj, [fieldName]: value });
  };

  const onDropdownChange = (e, fieldName) => {
    let value;
    value =
      typeof e.target.value === "string"
        ? DOMPurify.sanitize(e.target.value.trim())
        : DOMPurify.sanitize(e.target.value);
    if (!value) {
      setRegisterObj({ ...registerObj, [fieldName]: null });
    } else {
      setRegisterObj({ ...registerObj, [fieldName]: value });
    }
  };

  const handleSwitch = (e, fieldName) => {
    let value = "D";
    if (e.target.checked === true) {
      value = "M";
    }
    setRegisterObj({
      ...registerObj,
      [fieldName]: value,
    });
  };

  const onRegister = () => {
    setLoading(true);
    let isRegister = true;
    let missingField = null;
    let copyOfRequiredFields = Object.assign({}, requiredFields);
    if (registerObj.nameTypeId == "M") {
      delete copyOfRequiredFields.lastName;
    }
    if (registerObj.nameTypeId == "D") {
      delete copyOfRequiredFields.lastNamePaternal;
      delete copyOfRequiredFields.lastNameMaternal;
    }
    for (const key in copyOfRequiredFields) {
      const val = registerObj[key];
      if (val === "error" || !val) {
        missingField = key;
        isRegister = false;
        break;
      }
    }

    
    if (isRegister) {
      isRegister = phoneOtpVerified;
    }

    if (isRegister && !missingField) {
      console.log("submitting api request");
      // TODO props implementation
      const payload = generatePayloadObj(registerObj);
      axios
        .post(
          `${process.env.REACT_APP_CUS_OBD_BASE_ORDER_SERVICE_URL}/v1/pos/customer/registration`,
          payload,
          getHeaders(sessionStorage.getItem("accessToken"))
        )
        .then((response) => {
          if (response.status === 200 && response.data.responseStatus.status) {
            console.log(response.data.responseStatus.status);
            setIsRegistrationSuccess(true);
          } else {
            setIsRegistrationSuccess(false);
            setErrorToastStatus(true);
            setToastMessage(
              response.data.responseStatus.errors[0]?.description
            );
            console.log(response.data.responseStatus.errors[0]?.description);
          }
          setLoading(false);
        })
        .catch((error) => {
          setIsRegistrationSuccess(false);
          setErrorToastStatus(true);
          setToastMessage(toastMsg);
          setLoading(false);
        });
    } else {
      if (missingField) {
        setIsRegistrationSuccess(false);
        setErrorToastStatus(true);
        setToastMessage(
          "Please populate the field" + " " + labels[missingField]
        );
        setLoading(false);
      } else {
        setIsRegistrationSuccess(false);
        setErrorToastStatus(true);
        setToastMessage("Please verify phone to continue");
        setLoading(false);
      }
    }
  };

  const onClickBackButton = () => {
    if (registerObj?.editRequest) {
      history.push({
        pathname: "/customerLookup",
        state: {
          customerDetails: state.customerDetails,
          fromPath: "customerRegistration",
          pageInfo: {
            countryCode: state.pageInfo.countryCode,
            phoneNumber: state.pageInfo.phoneNumber,
            dob: state.pageInfo.dob,
          },
        },
      });
    } else {
      history.push("/home");
    }
  };

  const getCountryNames = () => {
    return Country.getAllCountries().map((country) => {
      return (
        <MenuItem key={country.isoCode} value={country.isoCode}>
          {country.name}
        </MenuItem>
      );
    });
  };

  const getStateNamesByCountryCode = (countrySelected) => {
    //const states = State.getStatesOfCountry(countrySelected);
    const states =  State.getAllStates().filter(
      (state) =>
      state.countryCode === countrySelected);
      const states1 = states.sort((a, b) => {
        if (a.isoCode < b.isoCode) {
          return -1;
        }
      });
    return states1.map((state) => {
      return (
        <MenuItem key={state.isoCode} value={state.isoCode}>
          {state.name}
        </MenuItem>
      );
    });


  }

  const getCountryIsdCodes = () => {
    return countries.map((country) => {
      return (
        <MenuItem key={country.code} value={country.code}>
          {country.name} ({country.isdCode})
        </MenuItem>
      );
    });
  };

  let LanguageList = [];

  const getLanguagePreferences = (countrySelected) => {
    LanguageList.push(<MenuItem selected key="English" value="EN">
      English
  </MenuItem>);
    let numberOfLangs = languageMap[countrySelected]?.length; 
    for(let i = 0 ; i <numberOfLangs; i++){ 
      LanguageList.push(<MenuItem key={languageMap[countrySelected][i]['language']} value={languageMap[countrySelected][i]['code']}>
      {languageMap[countrySelected][i]['language']}
    </MenuItem>);
    } 
        return (
             LanguageList
        )
  };

  return (
    <>
      {<Header history={history} heading="" />}
      {isRegistrationSuccess ? (
        <SuccessMessage
          successMessage={
            registerObj.editRequest
              ? "Customer Updated Successfully"
              : "Customer Registered Successfully"
          }
          firstName={
            registerObj.firstName +
            " " +
            (registerObj.nameTypeId == "D"
              ? registerObj?.lastName || ""
              : (registerObj.lastNamePaternal === null
                  ? ""
                  : registerObj.lastNamePaternal + " ") +
                " " +
                (registerObj.lastNameMaternal === null
                  ? ""
                  : registerObj.lastNameMaternal))
          }
          mobileNumber={
            getCountryIsdCodeByCode(registerObj.countryCode).isdCode +
            " " +
            registerObj.phoneNumber
          }
          resetRegistration={setIsRegistrationSuccess}
        />
      ) : (
        <Card className="registration-card">
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            open={errorToastStatus}
            autoHideDuration={6000}
            onClose={() => setErrorToastStatus(false)}
          >
            <Alert
              onClose={() => setErrorToastStatus(false)}
              severity="error"
              sx={{ width: "100%" }}
            >
              {toastMessage}
            </Alert>
          </Snackbar>
          <CardContent>
            <Box margin={2}>
              <ThemeProvider theme={theme}>
                <Typography variant="h5">
                  {registerObj.editRequest
                    ? "Update Customer"
                    : "Customer Registration"}
                  { registerObj.editRequest  ? <></> :<CameraAltIcon
                    disabled="true"
                    style={{ float: "right" }}
                    onClick={() => setOpenModal(true)}
                  ></CameraAltIcon>}
                </Typography>
              </ThemeProvider>
              <OCRModal
                promoterId={promoterId}
                openModal={openModal}
                setOpenModal={setOpenModal}
                setDetailsFromPhoto={setDetailsFromPhoto}
              />
              <Box mt={2}>
                <ThemeProvider theme={theme}>
                  <Typography variant="h3" marginBottom={1}>
                    Personal Information
                  </Typography>
                </ThemeProvider>

                <Grid item xs={12} sm={6} md={6}>
                  <Stack direction={"row"} spacing={1} className="age-mobile">
                    {/* <FormControl variant="standard" className="age">
                          <Select
                            value={registerObj["nameTypeId"]}
                            onChange={(e) => handleClick(e, "nameTypeId")}
                            onBlur={(e) => handleClick(e, "nameTypeId")}
                          >
                            <MenuItem defaultChecked>Choose name type</MenuItem>
                            <MenuItem value={"D"}>First/Last</MenuItem>
                            <MenuItem value={"M"}>Maternal/Paternal</MenuItem>
                          </Select>
                        </FormControl> */}
                    <FormControlLabel
                      control={
                        <Switch
                          checked={registerObj.nameTypeId == "M" ? true : false}
                          onChange={(e) => handleSwitch(e, "nameTypeId")}
                        />
                      }
                      label="Additional names ?"
                    />
                  </Stack>
                </Grid>
                <Grid
                  container
                  direction={"row"}
                  spacing={1}
                  alignItems="center"
                  justifyContent={"flex-start"}
                  className="personal-information"
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      required
                      className="field"
                      label="First Name(s)"
                      variant="standard"
                      fullWidth
                      value={
                        registerObj["firstName"] !== "error"
                          ? registerObj["firstName"]
                          : ""
                      }
                      error={registerObj["firstName"] === "error"}
                      helperText={
                        registerObj["firstName"] === "error" ? helperText : ""
                      }
                      onChange={(e) => onChangeHandler(e, "firstName")}
                      onBlur={(e) => onBlurHandler(e, "firstName")}
                    />
                  </Grid>

                  {registerObj.nameTypeId !== "M" ? (
                    <Grid item xs={12} sm={6} md={6}>
                      <TextField
                        required
                        className="field"
                        label="Last Name(s)"
                        variant="standard"
                        fullWidth
                        value={
                          registerObj["lastName"] !== "error"
                            ? registerObj["lastName"]
                            : ""
                        }
                        error={registerObj["lastName"] === "error"}
                        helperText={
                          registerObj["lastName"] === "error" ? helperText : ""
                        }
                        onChange={(e) => onChangeHandler(e, "lastName")}
                        onBlur={(e) => onBlurHandler(e, "lastName")}
                      />
                    </Grid>
                  ) : (
                    <>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        sx={{ width: "100%" }}
                      ></Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          className="field"
                          label="Paternal Name*"
                          variant="standard"
                          fullWidth
                          value={
                            registerObj["lastNamePaternal"] !== "error"
                              ? registerObj["lastNamePaternal"]
                              : ""
                          }
                          error={registerObj["lastNamePaternal"] === "error"}
                          helperText={
                            registerObj["lastNamePaternal"] === "error"
                              ? helperText
                              : ""
                          }
                          onChange={(e) =>
                            onChangeHandler(e, "lastNamePaternal")
                          }
                          onBlur={(e) => onBlurHandler(e, "lastNamePaternal")}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6} md={6}>
                        <TextField
                          className="field"
                          label="Maternal Name*"
                          variant="standard"
                          fullWidth
                          value={
                            registerObj["lastNameMaternal"] !== "error"
                              ? registerObj["lastNameMaternal"]
                              : ""
                          }
                          error={registerObj["lastNameMaternal"] === "error"}
                          helperText={
                            registerObj["lastNameMaternal"] === "error"
                              ? helperText
                              : ""
                          }
                          onChange={(e) =>
                            onChangeHandler(e, "lastNameMaternal")
                          }
                          onBlur={(e) => onBlurHandler(e, "lastNameMaternal")}
                        />
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid
                  container
                  direction={"row"}
                  spacing={1}
                  alignItems="center"
                  justifyContent={"flex-start"}
                  className="personal-information"
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <Stack direction={"row"} spacing={1} className="age-mobile">
                      <FormControl variant="standard" className="age">
                        <InputLabel id="demo-simple-select-standard-label">
                          Gender (Optional)
                        </InputLabel>
                        <Select
                          value={
                            registerObj["gender"] !== "error"
                              ? registerObj["gender"]
                              : null
                          }
                          onChange={(e) => onChangeHandler(e, "gender")}
                          onBlur={(e) => onDropdownChange(e, "gender")}
                        >
                          <MenuItem value={"M"}>Male</MenuItem>
                          <MenuItem value={"F"}>Female</MenuItem>
                          <MenuItem value={"S"}>Not Specified</MenuItem>
                        </Select>
                      </FormControl>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="Date of Birth (Optional)"
                        inputFormat="MM/DD/YYYY"
                        disableFuture={true}
                        maxDate={moment()
                          .subtract(18, "years")
                          .format("YYYY-MM-DD")}
                        // disabled = {registerObj.editRequest}
                        renderInput={(params) => (
                          <TextField {...params} variant="standard" fullWidth />
                        )}
                        value={
                          registerObj["dob"] !== "error"
                            ? registerObj["dob"]
                            : null
                        }
                        error={registerObj["dob"] === "error"}
                        helperText={
                          registerObj["dob"] === "error" ? helperText : ""
                        }
                        onChange={(e) => onChangeHandler(e, "dob")}
                        // onClose={(e) => onBlurHandler(e, "dob")}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      className="field"
                      label="Email (Optional)"
                      variant="standard"
                      fullWidth
                      value={
                        registerObj["email"] !== "error"
                          ? registerObj["email"]
                          : ""
                      }
                      error={registerObj["email"] === "error"}
                      helperText={
                        registerObj["email"] === "error" ? emailErrorText : ""
                      }
                      onChange={(e) => onChangeHandler(e, "email")}
                      onBlur={(e) => onBlurHandlerEmail(e, "email")}
                      InputProps={{
                        endAdornment: <EmailIcon></EmailIcon>,
                      }}
                      InputLabelProps={{ shrink: registerObj["email"] ? true : undefined }}  
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Stack direction={"row"} spacing={1} className="age-mobile">
                      <FormControl variant="standard" className="age">
                        <InputLabel id="demo-simple-select-standard-label">
                          Country Code *
                        </InputLabel>
                        <Select
                          disabled={phoneOtpVerified}
                          value={
                            registerObj["countryCode"] !== "error"
                              ? registerObj["countryCode"]
                              : ""
                          }
                          error={registerObj["countryCode"] === "error"}
                          onChange={(e) => onChangeHandler(e, "countryCode")}
                          onBlur={(e) => onBlurHandler(e, "countryCode")}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {getCountryIsdCodes()}
                        </Select>
                      </FormControl>
                      <TextField
                        required
                        label="Mobile Number"
                        variant="standard"
                        disabled={phoneOtpVerified}
                        className="mobile"
                        fullWidth
                        value={
                          registerObj["phoneNumber"] !== "error"
                            ? registerObj["phoneNumber"]
                            : ""
                        }
                        error={registerObj["phoneNumber"] === "error"}
                        helperText={
                          registerObj["phoneNumber"] === "error"
                            ? phoneErrorText || helperText
                            : ""
                        }
                        onChange={(e) => onChangeHandler(e, "phoneNumber")}
                        onBlur={(e) => onBlurHandler(e, "phoneNumber")}
                        InputProps={{
                          endAdornment: (
                            <Button
                              style={{ float: "right" }}
                              disabled={phoneOtpVerified}
                              onClick={() =>
                                handleOpenModalForOtpValidationPhone()
                              }
                            >
                              {phoneOtpVerified ? (
                                <VerifiedIcon
                                  sx={{ color: "green" }}
                                ></VerifiedIcon>
                              ) : (
                                "Verify"
                              )}
                            </Button>
                          ),
                        }}
                      />
                      {openOtpValidatorPhone && (
                        <OtpValidator
                          onCloseModal={handleCloseModalForOtpValidationPhone}
                          phoneOtpError={phoneOtpError}
                          verifierInfo={
                            getCountryIsdCodeByCode(registerObj.countryCode)
                              .isdCode +
                            " " +
                            registerObj.phoneNumber
                          }
                          onOtpSubmit={handleOtpValidationPhoneSubmitOtp}
                          onOtpResend={handleOtpValidationPhoneResendOtp}
                        />
                      )}
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2}>
                <ThemeProvider theme={theme}>
                  <Typography variant="h3" marginBottom={2}>
                    Address Information
                  </Typography>
                </ThemeProvider>
                <Grid
                  container
                  direction={"row"}
                  spacing={1}
                  alignItems="center"
                  justifyContent={"flex-start"}
                  className="personal-information"
                >
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      label="Street Address"
                      variant="standard"
                      fullWidth
                      //commenting for street address1 optional for LA event
                      // value={
                      //   registerObj["streetAddress1"] !== "error"
                      //     ? registerObj["streetAddress1"]
                      //     : ""
                      // }
                      // error={registerObj["streetAddress1"] === "error"}
                      // helperText={
                      //   registerObj["streetAddress1"] === "error"
                      //     ? helperText
                      //     : ""
                      // }
                      value={registerObj["streetAddress1"]}
                      onChange={(e) => onChangeHandler(e, "streetAddress1")}
                      //onBlur={(e) => onBlurHandler(e, "streetAddress1")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      label="Street Address 2 (Optional)"
                      variant="standard"
                      fullWidth
                      value={registerObj["streetAddress2"]}
                      onChange={(e) => onChangeHandler(e, "streetAddress2")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      required
                      label="City/Town"
                      variant="standard"
                      fullWidth
                      value={
                        registerObj["city"] !== "error"
                          ? registerObj["city"]
                          : ""
                      }
                      error={registerObj["city"] === "error"}
                      helperText={
                        registerObj["city"] === "error" ? helperText : ""
                      }
                      onChange={(e) => onChangeHandler(e, "city")}
                      onBlur={(e) => onBlurHandler(e, "city")}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                  <Stack direction={"row"} spacing={1} className="age-mobile">
                      <FormControl variant="standard" className="age">
                        <InputLabel id="demo-simple-select-standard-label">
                          State/Province *
                        </InputLabel>
                        <Select
                          value={
                            registerObj["state"] !== "error"
                              ? registerObj["state"]
                              : ""
                          }
                          error={registerObj["state"] === "error"}
                          helperText={
                            registerObj["state"] === "error" ? helperText : ""
                          }
                          onChange={(e) => onChangeHandler(e, "state")}
                          onBlur={(e) => onBlurHandler(e, "state")}
                        >
                          {getStateNamesByCountryCode(countrySelected)}
                        </Select>
                      </FormControl>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <Stack direction={"row"} spacing={1} className="age-mobile">
                      <FormControl variant="standard" className="age">
                        <InputLabel id="demo-simple-select-standard-label">
                          Country *
                        </InputLabel>
                        <Select
                          value={
                            registerObj["country"] !== "error"
                              ? registerObj["country"]
                              : ""
                          }
                          error={registerObj["country"] === "error"}
                          helperText={
                            registerObj["country"] === "error" ? helperText : ""
                          }
                          onChange={(e) => onChangeHandler(e, "country")}
                          onBlur={(e) => onBlurHandler(e, "country")}
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          {getCountryNames()}
                        </Select>
                      </FormControl>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField
                      required
                      label="Postal/Zip Code"
                      variant="standard"
                      fullWidth
                      value={
                        registerObj["zipCode"] !== "error"
                          ? registerObj["zipCode"]
                          : ""
                      }
                      error={registerObj["zipCode"] === "error"}
                      helperText={
                        registerObj["zipCode"] === "error" ? helperText : ""
                      }
                      onChange={(e) => onChangeHandler(e, "zipCode")}
                      onBlur={(e) => onBlurHandler(e, "zipCode")}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2}>
                <ThemeProvider theme={theme}>
                  <Typography variant="h3">
                    Communication Preferences
                  </Typography>
                </ThemeProvider>
                <Grid
                  container
                  direction={"row"}
                  spacing={5}
                  alignItems="center"
                  justifyContent={"flex-start"}
                >
                  <Grid item>
                    <label>Marketing Communications</label>
                  </Grid>

                  <Grid item>
                    <FormControlLabel
                      label="SMS/WhatsApp"
                      control={
                        <Checkbox
                          value={registerObj["smsPreference"]}
                          checked={registerObj["smsPreference"]}
                          onChange={(e) =>
                            onChangeHandlerChecked(e, "smsPreference")
                          }
                        />
                      }
                    />
                  </Grid>
                  <Grid item>
                    <FormControlLabel
                      label="Email"
                      control={
                        <Checkbox
                          value={registerObj["emailPreference"]}
                          checked={registerObj["emailPreference"]}
                          onChange={(e) =>
                            onChangeHandlerChecked(e, "emailPreference")
                          }
                        />
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={2}>
                <Grid
                  container
                  direction={"row"}
                  spacing={1}
                  alignItems="center"
                  justifyContent={"flex-start"}
                  className="language-preference"
                >
				 <Grid item xs={12} sm={6} md={3}>
                    <Stack direction={"row"} spacing={1} className="age-mobile">
                    <FormControl variant="standard" className="age">
                      <InputLabel  id="demo-simple-select-standard-label">
                        Language Preference
                      </InputLabel>
                      <Select
                        required
                        fullWidth
                        value={
                          registerObj["languagePreference"] !== "error"
                            ? registerObj["languagePreference"]
                            : ""
                        }
                        error={registerObj["languagePreference"] === "error"}
                        helperText={
                          registerObj["languagePreference"] === "error"
                            ? helperText
                            : ""
                        }
                        onChange={(e) =>
                          onChangeHandler(e, "languagePreference")
                        }
                        onBlur={(e) => onBlurHandler(e, "languagePreference")}
                      >

                         { getLanguagePreferences(countrySelected) }
                      </Select>
                    </FormControl>
                    </Stack>
                  </Grid>
                  </Grid>
                  </Box>
              <Stack
                direction={"row"}
                spacing={1}
                marginTop={3}
                marginBottom={3}
                className="buttons"
              >
                <Button
                  className="button-primary"
                  variant="contained"
                  onClick={() => onRegister()}
                >
                  {loading
                    ? "Loading ..."
                    : registerObj.editRequest
                    ? "Update"
                    : "Register"}
                </Button>
                <Button
                  className="button-secondary"
                  variant="outlined"
                  onClick={() => onClickBackButton()}
                >
                  Back
                </Button>
              </Stack>
            </Box>
          </CardContent>
        </Card>
      )}
    </>
  );
}

export default CustomerRegistration;
