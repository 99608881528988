import React, { useState, useEffect } from "react";
import {
  Alert,
  AlertTitle,
  Grid,
  Stack,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  FormHelperText,
  Typography,
  Card,
  CardContent,
  Snackbar
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import "./styles.css";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Header from "../header/header";
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';
import {
  getHeaders,
  validatePhoneNumber,
  validateEmail,
} from "../utility/utility";
import { countries } from "../utility/country";
import DOMPurify from 'dompurify';
import { createTheme, ThemeProvider } from "@mui/material/styles";
const theme = createTheme();

const rObj = {
  firstName: "",
  lastName: "",
  email: "",
  countryCode: sessionStorage.getItem("countryCode"),
  mobileNumber: "",
  submitMsg: "",
  submitStatus: "",
};

const labels = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  countryCode: "Country Code",
  mobileNumber: "Mobile Number",
};

const requiredFieldsObj = {
  firstName: "",
  lastName: "",
  email: "",
  countryCode: "",
  mobileNumber: "",
};

theme.typography.h3 = {
  fontSize: "1.2rem",
};

theme.typography.h5 = {
  fontSize: "1.5rem",
};

const toastMsg = "Some Error Occurred, Please try again";

function PromoterRegistration(props) {
  const history = useHistory();
  // const [registerState, setRegisterState] = useState(true)
  const [registerObj, setRegisterObj] = useState(rObj);
  const [loading, setLoading] = React.useState(false);
  const helperText = "Please fill this field";
  const [toastMessage, setToastMessage] = useState(toastMsg);
  const [errorToastStatus, setErrorToastStatus] = useState(false);
  const [emailErrorText, setEmailErrorText] = useState("");
  const [phoneErrorText, setPhoneErrorText] = useState("");


  useEffect(() => {
  
    try {

      setRegisterObj({ ...registerObj, ["countryCode"]: sessionStorage.getItem("countryCode") });
      // valid token format
    } catch (error) {
      // invalid token format
    }
  }, []);


  const getCountry = () => {
    return countries.map((country) => {
      return (
        <MenuItem key={country.code} value={country.code}>
          {country.name} ({country.isdCode})
        </MenuItem>
      );
    });
  };

  const onAnotherRegister = () => {
    setRegisterObj(rObj);
  };

  const onRegister = () => {
    setLoading(true);
    let isRegister = true;
    let missingField = null;
    for (const key in requiredFieldsObj) {
      const val = registerObj[key];
      if (val === "error" || !val) {
        missingField = key;
        isRegister = false;
        break;
      }
    }

    if (!isRegister) {
      if (missingField) {
        setErrorToastStatus(true);
        setToastMessage(
          "Please populate the field" + " " + labels[missingField]
        );
        // setRegisterObj({ ...registerObj, [missingField]: "error" });
        setLoading(false);
      }
      return;
    }

    let countryIsdCode = countries.filter(
      (country) => country.code === registerObj.countryCode
    )?.[0]?.isdCode.replace("+", "");

    let submittedObj = {};

    submittedObj.firstName = registerObj.firstName;
    submittedObj.lastName = registerObj.lastName;
    submittedObj.email = registerObj.email;
    submittedObj.phoneNumber =
      countryIsdCode + registerObj.mobileNumber;
    submittedObj.countryCode=  registerObj.countryCode;
    submittedObj.requestInitiatedBy = "CUSOBD"

    axios
      .post(
        `${process.env.REACT_APP_CUS_OBD_BASE_ORDER_SERVICE_URL}/v1/pos/promoter/registration`,
        submittedObj,
        getHeaders(
          sessionStorage.getItem("accessToken") !== null
            ? sessionStorage.getItem("accessToken")
            : ""
        )
      )
      .then((response) => {
        console.log(response);
        if (response.data.status === "CREATED" && response.status === 200) {
          registerObj["submitStatus"] = "OK";
          registerObj["submitMsg"] = "Successful";
        } else {
          registerObj["submitStatus"] = "ERROR";
          registerObj["submitMsg"] = response.data.message;
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 500) {
          registerObj["submitStatus"] = "ERROR";
          registerObj["submitMsg"] =
            error?.response?.data?.message ||
            "Some error occurred, please try again.";
        } else {
          registerObj["submitStatus"] = "ERROR";
          registerObj["submitMsg"] = "Some error occurred, please try again.";
        }
        setLoading(false);
      });
  };

  const onChangeHandler = (e, fieldName) => {
    const value = DOMPurify.sanitize(e.target.value);
    setRegisterObj({ ...registerObj, [fieldName]: value });
  };

  const onBlurHandler = (e, fieldName) => {
    const value =
      typeof e.target.value === "string"
        ? DOMPurify.sanitize(e.target.value.trim())
        : DOMPurify.sanitize(e.target.value);

    

    if (!value) setRegisterObj({ ...registerObj, [fieldName]: "error" });
    else if (fieldName === "email") {
      const isValid = validateEmail(value);
      if (!isValid) {
        setRegisterObj({ ...registerObj, [fieldName]: "error" });
        setEmailErrorText("Please enter valid email");
      } else {
        setRegisterObj({ ...registerObj, [fieldName]: value });
        setEmailErrorText("");
      }
    }
    else if (fieldName === "mobileNumber") {
      const isValid = validatePhoneNumber(registerObj.countryCode, value);
      if (!isValid) {
        setRegisterObj({ ...registerObj, [fieldName]: "error" });
        setPhoneErrorText("Please enter valid mobile number");
      } else {
        setRegisterObj({ ...registerObj, [fieldName]: value });
        setPhoneErrorText("");
      }
    } 
    else setRegisterObj({ ...registerObj, [fieldName]: value });
  };

  return (
    <>
      {<Header history={history} heading="" />}
      <Card className="registration-card">
          
      <CardContent className="card-content">
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "center", position: 'absolute' }}
            open={errorToastStatus}
            autoHideDuration={6000}
            className="card-content-toast"
            onClose={() => setErrorToastStatus(false)}
          >
            <Alert
              onClose={() => setErrorToastStatus(false)}
              severity="error"
              sx={{ width: "100%" }}
            >
              {toastMessage}
            </Alert>
          </Snackbar>
      <Grid
        mt={6}
        container
        direction={"row"}
        spacing={1}
        alignItems="center"
        justifyContent={"center"}
        className="registration-content"
      >
        <Grid item sm={12} md={4} lg={4} mt={4} className="container">
          <ThemeProvider theme={theme}>
            <Typography variant="h5" component="h6">
              Marketing Promoter Registration
            </Typography>
          </ThemeProvider>
          <TextField
            required
            className="field"
            label="First Name"
            variant="standard"
            fullWidth
            value={
              registerObj["firstName"] !== "error"
                ? registerObj["firstName"]
                : ""
            }
            error={registerObj["firstName"] === "error"}
            helperText={registerObj["firstName"] === "error" ? helperText : ""}
            onChange={(e) => onChangeHandler(e, "firstName")}
            onBlur={(e) => onBlurHandler(e, "firstName")}
          />
          <TextField
            required
            className="field"
            label="Last Name"
            variant="standard"
            fullWidth
            value={
              registerObj["lastName"] !== "error" ? registerObj["lastName"] : ""
            }
            error={registerObj["lastName"] === "error"}
            helperText={registerObj["lastName"] === "error" ? helperText : ""}
            onChange={(e) => onChangeHandler(e, "lastName")}
            onBlur={(e) => onBlurHandler(e, "lastName")}
          />
          <TextField
            required
            className="field"
            label="Email"
            variant="standard"
            fullWidth
            value={registerObj["email"] !== "error" ? registerObj["email"] : ""}
            error={
              registerObj["email"] !== "" &&
              (registerObj["email"] === "error" ||
                !validateEmail(registerObj["email"]))
            }
            helperText={registerObj["email"] === "error" ? emailErrorText || helperText : ""}
            onChange={(e) => onChangeHandler(e, "email")}
            onBlur={(e) => onBlurHandler(e, "email")}
            InputProps={{
              endAdornment: (
                <EmailIcon></EmailIcon>
              ),
            }}
          />
          <Stack direction={"row"} spacing={1} className="age-mobile" mt={4}>
            <FormControl variant="standard" className="age">
              <InputLabel id="demo-simple-select-standard-label">
                Country Code
              </InputLabel>
              <Select
                required
                value={
                  registerObj["countryCode"] !== "error"
                    ? registerObj["countryCode"]
                    : ""
                }
                error={registerObj["countryCode"] === "error"}
                onChange={(e) => onChangeHandler(e, "countryCode")}
                onBlur={(e) => onBlurHandler(e, "countryCode")}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {getCountry()}
              </Select>
              {registerObj["countryCode"] === "error" ? (
                <FormHelperText className="select-error">
                  {helperText}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
            <TextField
              required
              label="Mobile Number"
              variant="standard"
              className="mobile"
              type="tel"
              fullWidth
              value={
                registerObj["mobileNumber"] !== "error"
                  ? registerObj["mobileNumber"]
                  : ""
              }
              // error={
              //   registerObj["mobileNumber"] !== "" &&
              //   (registerObj["mobileNumber"] === "error" ||
              //     registerObj["mobileNumber"])
              // }
              error={registerObj["mobileNumber"] === "error"}
              helperText={
                registerObj["mobileNumber"] === "error" ? phoneErrorText || helperText : ""
              }
              onChange={(e) => onChangeHandler(e, "mobileNumber")}
              onBlur={(e) => onBlurHandler(e, "mobileNumber")}
              InputProps={{
                endAdornment: (
                  <PhoneIphoneIcon></PhoneIphoneIcon>
                ),
              }}
            />
          </Stack>
          <Stack
            direction={"column"}
            alignItems="center"
            spacing={2}
            className="buttons"
          >
            <Button
              className="button-primary"
              loading={loading}
              variant="contained"
              onClick={() => onRegister()}
            >
              {loading ? "Loading ..." : "Register"}
            </Button>
            <Button
              className="button-secondary"
              variant="outlined"
              onClick={() => history.push("/home")}
            >
              Back
            </Button>
          </Stack>
          {registerObj.submitStatus === "ERROR" && (
            <Alert icon={<ErrorIcon fontSize="inherit" />} severity="error">
              <AlertTitle>Error</AlertTitle>
              {registerObj.submitMsg}
            </Alert>
          )}
          {registerObj.submitStatus === "OK" && (
            <Alert
              icon={<CheckCircleIcon fontSize="inherit" />}
              severity="success"
            >
              <AlertTitle>Success</AlertTitle>
              Marketing Promoter registration is {registerObj.submitMsg}.
              Please convey the message to Marketing Promoter.
            </Alert>
          )}
          <Stack
            direction={"column"}
            alignItems="left"
            spacing={1}
            className="buttons"
          >
            {registerObj.submitStatus === "OK" && (
              <Button
                className="button-primary"
                variant="contained"
                onClick={() => onAnotherRegister()}
              >
                Register Another Marketing Promoter
              </Button>
            )}
          </Stack>
        </Grid>
      </Grid>
      </CardContent>
      </Card>
    </>
  );
}

export default PromoterRegistration;
