import React, { useEffect } from "react";
import { Chip, Stack } from "@mui/material";
import jwt_decode from "jwt-decode";
import PageviewIcon from "@mui/icons-material/Pageview";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { useHistory } from "react-router-dom";
import {
  CUSTOMER_LOOKUP,
  CUSTOMER_REGISTRATION,
  PROMOTER_REGISTRATION,
} from "../../routes";
import Header from "../header/header";

function HomePage(props) {
  const history = useHistory();
  const [adminUser, setAdminUser] = React.useState(false);

  useEffect(() => {
    const idToken = sessionStorage.getItem("idToken");
    const token = jwt_decode(idToken);
    var accessRole = token.role;
    const accessToken = sessionStorage.getItem("accessToken");
    const token1 = jwt_decode(accessToken);
    var countryCode = token1.countryCode;
    if(countryCode === undefined || countryCode ==="")
    {
      countryCode = process.env.REACT_APP_DEFAULT_COUNTRY;
    }
    sessionStorage.setItem("countryCode", countryCode);
    setAdminUser(
      accessRole?.includes(process.env.REACT_APP_CUS_OBD_ADMIN_ACCESS) ||
        accessRole === process.env.REACT_APP_CUS_OBD_ADMIN_ACCESS
    );
    var id = token.sub;
  }, []);

  return (
    <>
      {<Header history={history} heading="" />}
      <Stack direction={"column"} spacing={3} alignItems="center" margin={10}>
        {adminUser && (
          <Chip
            onClick={() => history.push(PROMOTER_REGISTRATION)}
            icon={<SupportAgentIcon />}
            label="Promoter Registration"
            size="medium"
            sx={{
              justifyContent: "flex-center",
              width: "300px",
              height: "75px",
              padding: "20px 10px",
              background: "#FFDD00",
              color: "black",
            }}
          />
        )}
        <Chip
          onClick={() => history.push(CUSTOMER_LOOKUP)}
          icon={<PageviewIcon />}
          label="Customer Lookup"
          size="medium"
          sx={{
            justifyContent: "flex-center",
            width: "300px",
            height: "75px",
            padding: "20px 10px",
            background: "#FFDD00",
            color: "black",
          }}
        />
        <Chip
          onClick={() => history.push(CUSTOMER_REGISTRATION)}
          icon={<PersonAddIcon />}
          label="Customer Registration"
          size="medium"
          sx={{
            justifyContent: "flex-center",
            width: "300px",
            height: "75px",
            padding: "20px 10px",
            background: "#FFDD00",
            color: "black",
          }}
        />
      </Stack>
    </>
  );
}

export default HomePage;
